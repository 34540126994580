import { useMutation } from '@apollo/client';
import { SIGNIN } from './graphql/signin.graphql';
import { REGISTER } from './graphql/register.graph';
import { useNavigate } from 'react-router-dom';

export const useSignIn = () => {
  const navigate = useNavigate();

  const [login, { data, loading, error }] = useMutation(SIGNIN, {
    onCompleted: () => navigate('/signed-in'),
  });

  return { login, data, loading, error };
};

export const useSignUp = () => {
  const navigate = useNavigate();

  const [register, { data, loading, error }] = useMutation(REGISTER, {
    onCompleted: () => navigate('/sign-in'),
  });

  return { register, data, loading, error };
};
