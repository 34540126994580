import { Box, Heading } from '@chakra-ui/react';
import { UserLayout } from './UserLayout';

export const SignedIn = () => {
  return (
    <UserLayout>
      <Box textAlign='center'>
        <Heading size='md'>Welcome</Heading>
      </Box>
    </UserLayout>
  );
};
