import { Box, Button, Flex, Heading, Spacer } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';

export const NavBar = () => {
  const navigate = useNavigate();

  const signinClick = () => {
    navigate('/sign-in');
  };

  return (
    <Flex m='2'>
      <Box m='2'>
        <Link to='/'>
          <Heading ml='4' size='md'>
            Social Media
          </Heading>
        </Link>
      </Box>
      <Spacer />
      <ThemeToggle />
      <Box>
        <Button onClick={signinClick} colorScheme='teal' mr='4'>
          Sign In
        </Button>
      </Box>
    </Flex>
  );
};
