import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StrictMode } from 'react';
import { App } from './App';
import { WebVitals } from './metrics/WebVitals';
import * as serviceWorker from './serviceWorker';

const envProd = process.env.NODE_ENV === 'production';
const developmentUri = process.env.REACT_APP_DEV_URI;
const productionUri = process.env.REACT_APP_PROD_URI;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: envProd ? productionUri : developmentUri,
});

const container = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(container);
root.render(
  <StrictMode>
    <Router>
      <ColorModeScript />
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-ignore
WebVitals();
