import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation RegisterMutation($registerInput: RegisterInput!) {
    register(registerInput: $registerInput) {
      id
      username
      email
      createdAt
      password
    }
  }
`;
