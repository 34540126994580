import { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { useSignIn } from './use-user';
import { UserLayout } from './UserLayout';

export const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, loading, error } = useSignIn();

  const submit = async (event: any) => {
    event.preventDefault();
    try {
      await login({
        variables: {
          loginInput: {
            username,
            password,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <UserLayout>
      <Box p='2'>
        <Box textAlign='center'>
          <Heading size='md'>Sign In With</Heading>
        </Box>
        <Box>
          {error && (
            <Alert status='error' mt='4' borderRadius='4'>
              <AlertIcon />
              <AlertDescription>{error?.message}</AlertDescription>
            </Alert>
          )}
        </Box>
        <Box my='4'>
          <form onSubmit={(e) => submit(e)}>
            <FormControl id='username'>
              <FormLabel>Username</FormLabel>
              <Input
                onChange={(e) => setUsername(e.target.value.toLowerCase())}
                type='text'
              />
            </FormControl>
            <FormControl id='password' mt='4'>
              <FormLabel>Password</FormLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type='password'
              />
            </FormControl>
            {loading}
            <Button
              isLoading={loading}
              type='submit'
              colorScheme='teal'
              width='full'
              mt='4'
            >
              Sign In
            </Button>
            <Box textAlign='center' mt='1'>
              <Link fontSize='xs' as={RouteLink} to='/sign-up'>
                Need an account? Sign up
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </UserLayout>
  );
};
